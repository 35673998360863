/* eslint-disable no-restricted-globals */
import { Header } from '@packages/modules/src/Header/Header';
import { Usp } from '@packages/cms-components/src/modules/Usp/index';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { GlycerinLoginStatus } from '@packages/tracking/src/types/enums';
import { useTracking } from '@packages/tracking/src/hooks/useTracking/useTracking';
import { useParams } from 'next/navigation';
import { useCustomerInformation } from '@packages/modules/src/hooks/useCustomerInformation/useCustomerInformation';
import { useLoginState } from '@packages/shared/src/hooks/useLoginState/useLoginState';
import Script from 'next/script';
import type { FC } from 'react';
import { useCallback, useEffect, useState, useSyncExternalStore } from 'react';
import { useIntl } from 'react-intl';
import config from '@packages/config';

const PatchedHeader: FC<{ isMinimal?: boolean }> = ({ isMinimal = false }) => (
  <>
    <Script
      id="history-state-patch"
      dangerouslySetInnerHTML={{
        __html:
          'var _wr=function(t){var e=history[t];return function(){var r=e.apply(this,arguments),a=new Event(t);return a.arguments=arguments,window.dispatchEvent(a),r}};history.pushState=_wr("pushState"),history.replaceState=_wr("replaceState");',
      }}
    />
    <Header isMinimal={isMinimal} />
    {!isMinimal && config.usp.isEnabled && <Usp />}
  </>
);

const HeaderPage = () => {
  const params = useParams();
  const path = params ? params.path : [];
  const { language } = useI18n();
  const [localeSwitch, setLocaleSwitch] = useState<boolean>(false);
  const fullHeader = ['warenkorb', 'mein-konto', 'mon-compte', 'merkzettel', 'header'];
  const smallHeader = ['kasse', 'smallheader'];
  const allRoutes = [...fullHeader, ...smallHeader];
  const routerPath = Array.isArray(path) && path[0] === 'fr' ? path.slice(1) : path || [];

  const dispatchGTMEvent = useTracking();
  const { isLoading, customerInformation } = useCustomerInformation();
  const { loggedIn } = useLoginState();
  const intl = useIntl();
  const [loginStatus, setLoginStatus] = useState<GlycerinLoginStatus>();

  const getLoginStatus = useCallback(async () => {
    // eslint-disable-next-line import/no-extraneous-dependencies
    const { apiKeyManager } = await import('@empiriecom/mybuy-session/ApiKey');
    const apiKey = await apiKeyManager.getApiKey();
    const loginType = apiKeyManager.getTokenContents(apiKey)?.type;

    if (loginType && loginType === 1) {
      setLoginStatus(GlycerinLoginStatus.SOFTLOGGEDIN);
      return;
    }
    if (loginType && loginType >= 1) {
      setLoginStatus(GlycerinLoginStatus.LOGGEDIN);
      return;
    }
    setLoginStatus(GlycerinLoginStatus.NOTLOGGEDIN);
  }, []);

  useEffect(() => {
    getLoginStatus().catch(() => {
      // ignore
    });
  }, [getLoginStatus, loggedIn]);

  useEffect(() => {
    if (!isLoading && loginStatus && customerInformation) {
      dispatchGTMEvent({
        event: 'dataLayerReady',
        Sprache: intl.locale,
        LoginStatus: loginStatus,
        ...customerInformation,
        visitor: {
          ...customerInformation.visitor,
          login: loggedIn,
        },
      });
    }
    // Fix for CORE-556: searchId has been added to trigger dataLayerReady on MBA/SERP changes.
    // TODO: rework events within tracking project
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, customerInformation?.visitor.login, intl.locale, loggedIn, loginStatus]);

  const events = ['popstate', 'pushState', 'replaceState'];
  function subscribe(callback: () => void) {
    for (const event of events) {
      window.addEventListener(event, callback);
    }
    return () => {
      for (const event of events) {
        window.removeEventListener(event, callback);
      }
    };
  }

  const syncPath = useSyncExternalStore(
    subscribe,
    () => window.location.pathname,
    () => routerPath,
  );

  // slice the path from store to remove empty first element from array: '/warenkorb' -> ['', 'warenkorb']
  const pagePath = Array.isArray(syncPath) ? syncPath : syncPath.slice(1).split('/');

  // use routerPath if pagePath is not in allRoutes
  const headerRoute =
    // eslint-disable-next-line no-nested-ternary
    typeof window !== 'undefined' && allRoutes.includes(pagePath[0])
      ? pagePath[0]
      : routerPath[0] === 'app'
        ? routerPath[1]
        : routerPath[0];

  const [currentPath, setCurrentPath] = useState<string>(headerRoute);
  // reload page if locale changes to reload content in correct language
  useEffect(() => {
    if (localeSwitch) {
      window.location.reload();
    }
    setLocaleSwitch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    setCurrentPath(headerRoute);
  }, [headerRoute]);

  // return Header if path is known
  if (fullHeader.includes(currentPath)) {
    return <PatchedHeader />;
  }
  // return minimal Header if path is known
  if (smallHeader.includes(currentPath)) {
    return <PatchedHeader isMinimal />;
  }
  // Reload page if path is unknown and hope for serverside routing
  if (typeof window !== 'undefined') {
    window.location.reload();
    return null;
  }

  // This output should never be seen. There must always be a Header!
  return (
    <>
      <Header />
      {config.usp.isEnabled && <Usp />}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default HeaderPage;
